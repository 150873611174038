import api from './index'
import { axios } from '@/utils/request'

// 获取公告类型
export function announceType() {
    return axios({
        url: api.AnnounceType,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取所有公告
export function announceList() {
    return axios({
        url: api.AnnounceList,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取一个公告详情
export function announceRead(parameter) {
    return axios({
        url: api.AnnounceRead,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增公告
export function announceAdd(parameter) {
    return axios({
        url: api.AnnounceAdd,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改公告
export function announceEdit(parameter) {
    return axios({
        url: api.AnnounceEdit,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除公告
export function announceDelete(parameter) {
    return axios({
        url: api.AnnounceDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
